import React, { useState } from "react";
import styled from "styled-components";

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Prompt = styled.input`
    max-width: 50%;
    margin: 0 auto 20px;
    border-radius: 5px;
    padding: 5px;
    border: none;
`;

const Timer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgb(40, 40, 40);
`;

const Text = styled.h1`
  font-size: 48px;
  text-align: center;
  color: rgb(255, 40, 75);
`;

const Count = styled.h1`
  font-size: 60px;
  text-align: center;
  color: rgb(255, 40, 75);
  margin-bottom: 8rem;
`;

const Button = styled.button`
height: 2rem;
width: 5rem;
border-radius: 5px;
font-weight: 700;
background-color: rgb(40, 40, 40);
border: 2px solid rgb(255, 40, 75);
color: rgb(255, 40, 75);
margin: auto;
&:hover {
    cursor: pointer;
    background-color: rgb(255, 40, 75);
    color: rgb(40, 40, 40);
    transition: 0.2s ease;
    border: none;
}
`;

export default function WorkTimer() {
    const [countdown, setCountdown] = useState("");
    const [inputTime, setInputTime] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        let [hours, minutes] = inputTime.split(":");
        hours = parseInt(hours);
        minutes = parseInt(minutes);
        const loweredInput = inputTime.toLowerCase();
        if (hours < 12 && loweredInput.includes("pm")) {
            hours += 12;
        } else if (hours === 12 && loweredInput.includes("am")) {
            hours = 0;
        }
        const userDate = new Date();
        userDate.setHours(hours);
        userDate.setMinutes(minutes);
        const targetTime = new Date(userDate.getTime() + 8.5 * 60 * 60 * 1000);
        const countdownInterval = setInterval(() => {
            const now = new Date().getTime();
            const timeRemaining = targetTime - now;
            const hours = Math.floor(
                (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor(
                (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
            );
            let seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
            if (seconds < 10) {
                seconds = "0" + seconds;
            }
            setCountdown(`${hours}:${minutes}:${seconds}`);
            if (timeRemaining < 0) {
                clearInterval(countdownInterval);
                setCountdown("Go Home!");
            }
        }, 1000);
    };

    return (
        <Timer>
            {countdown ? (
                <Count>{countdown}</Count>
            ) : (
                <Form onSubmit={handleSubmit}>
                    <Text>What time did you arrive?</Text>
                    <Prompt
                        type="text"
                        value={inputTime}
                        onChange={(e) => setInputTime(e.target.value)}
                        placeholder="HH:MM am/pm"
                    />
                    <Button type="submit">Start</Button>
                </Form>
            )}
        </Timer>
    );
}
