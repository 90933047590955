import React from 'react';
import { render } from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import Calculator from './calculator/Components/Calculator';
import WorkTimer from './timer/Timer';

const root = document.querySelector('#root');

render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<App />} />
                <Route path={'/calculator'} element={<Calculator />} />
                <Route path={'/timer'} element={<WorkTimer />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
    root
);